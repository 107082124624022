import { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Mutation } from 'react-apollo';
import toggleItemBookmarkMutation from 'graphql/mutations/toggleItemBookmark.graphql';
import HeartIcon from 'components/atoms/HeartIcon';
import Button from 'components/atoms/Button';
import AuthenticationProvider from 'components/atoms/AuthenticationProvider';
import Modal from 'components/atoms/Modal';
import ButtonLink from 'components/atoms/ButtonLink';

const StyledHeartButton = styled(Button)`
  color: ${({ theme, liked }) => (liked ? '#D0021B' : theme.colors.neutral4)};
  background: none;

  &:hover,
  &:focus {
    color: ${({ theme, liked }) => (liked ? '#D0021B' : theme.colors.neutral2)};
    background: none;
    outline: none;
  }
`;

const ANIMATION_DURATION = 500;

const likeAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`;

const StyledHeartIcon = styled(({ className }) => <HeartIcon className={className} />)`
  fill: currentColor;
  font-size: 1.2em;

  ${({ isLikeAnimation }) =>
    isLikeAnimation &&
    css`
      animation: ${likeAnimation} ${ANIMATION_DURATION}ms ease-out;
    `};
`;

class AddToBookmarksButton extends Component {
  state = {
    prevBookmarked: this.props.isBookmarked,
    liked: this.props.isBookmarked,
    isLikeAnimation: false,
    isModalShown: false,
    isModalMounted: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isBookmarked !== state.prevBookmarked) {
      return {
        liked: props.isBookmarked,
        isLikeAnimation: false,
        prevBookmarked: props.isBookmarked,
      };
    }

    return null;
  }

  toggleLike = (runMutation) => {
    if (!this.props.isAuthenticated) {
      this.setState({ isModalMounted: true }, () => {
        this.setState({
          isModalShown: true,
        });
      });
      return;
    }

    runMutation();

    this.setState(
      (state) => ({
        liked: !state.liked,
        isLikeAnimation: !state.liked,
      }),
      () => {
        if (this.state.like) {
          setTimeout(() => {
            this.setState({
              isLikeAnimation: false,
            });
          }, ANIMATION_DURATION);
        }
      },
    );
  };

  handleModalHide = () => {
    this.setState({
      isModalShown: false,
    });
  };

  handleError = () => {
    this.setState({
      liked: this.props.isBookmarked,
    });
  };

  render() {
    return (
      <>
        {this.state.isModalMounted && (
          <Modal
            show={this.state.isModalShown}
            title="Необходимо войти"
            actions={[
              <ButtonLink primary inline to="/signin">
                Войти
              </ButtonLink>,
              <ButtonLink neutral inline to="/signup">
                Зарегистрироваться
              </ButtonLink>,
            ]}
            onHide={this.handleModalHide}
          >
            <p>Для того, чтобы воспользоваться закладками необходимо войти в личный кабинет.</p>
          </Modal>
        )}
        <Mutation
          mutation={toggleItemBookmarkMutation}
          variables={{ itemId: this.props.itemId }}
          update={(cache, { data: { toggleItemBookmark } }) => {
            cache.writeData({
              id: `Item:${this.props.itemId}`,
              data: { isBookmarked: toggleItemBookmark },
            });
          }}
          onError={this.handleError}
        >
          {(runMutation, { loading }) => (
            <StyledHeartButton
              onClick={() => loading || this.toggleLike(runMutation)}
              liked={this.state.liked}
              className={this.props.className}
            >
              <StyledHeartIcon isLikeAnimation={this.state.isLikeAnimation} />
              {loading && ' Загрузка...'}
              {!this.state.liked && !loading && ' В закладки'}
            </StyledHeartButton>
          )}
        </Mutation>
      </>
    );
  }
}

export default (props) => (
  <AuthenticationProvider>
    {(isAuthenticated) => <AddToBookmarksButton {...props} isAuthenticated={isAuthenticated} />}
  </AuthenticationProvider>
);
