import { Component } from 'react';
import styled from 'styled-components';
import CartIcon from 'react-icons/lib/md/shopping-cart';
import Button from 'components/atoms/Button';
import ButtonLink from 'components/atoms/ButtonLink';
import Modal from 'components/atoms/Modal';
import QuantityInput from 'components/atoms/QuantityInput';
import UpdateCartMutation from 'components/atoms/UpdateCartMutation';
import SendMessageToOffice from 'components/organisms/SendMessageToOffice';

const StyledCartIcon = styled(CartIcon)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2em;
`;

const StyledQuantityInput = styled(QuantityInput)`
  margin-bottom: 2em;
`;

class AddToCartButton extends Component {
  state = {
    quantity: '1',
    isQuantityModalShown: false,
    isSuccessModalShown: false,
    isMessageModalShown: false,
    isModalsMounted: false,
  };

  handleClick = (runMutation) => {
    this.setState(
      {
        isModalsMounted: true,
      },
      async () => {
        if (this.props.item.residue === 0) {
          this.setState({
            isMessageModalShown: true,
          });
          return;
        }

        if (!this.props.quantity) {
          this.setState({
            isQuantityModalShown: true,
          });
          return;
        }

        await runMutation();
      },
    );
  };

  handleQuantityModalHide = () => {
    this.setState({
      quantity: '1',
      isQuantityModalShown: false,
    });
  };

  handleQuantityChange = (quantity) => {
    this.setState({
      quantity,
    });
  };

  handleError = () => {
    window.alert('Произошла ошибка. Попробуйте позднее.');
  };

  handleSuccess = () => {
    this.setState({
      quantity: '1',
      isQuantityModalShown: false,
      isSuccessModalShown: true,
    });

    if (typeof window.ym !== 'undefined') {
      window.ym(28073193, 'reachGoal', 'add_to_cart');
    }

    if (this.props.afterSuccess) {
      this.props.afterSuccess();
    }
  };

  handleSuccessModalHide = () => {
    this.setState({
      isSuccessModalShown: false,
    });
  };

  handleMessageModalHide = () => {
    this.setState({
      isMessageModalShown: false,
    });
  };

  render() {
    return (
      <>
        {this.state.isModalsMounted && (
          <>
            {this.props.item.residue === 0 && (
              <SendMessageToOffice
                subject={`Запрос информации по товару "${this.props.item.name}" (${this.props.item.article})`}
                title={`Запрос информации по товару "${this.props.item.name}" (${this.props.item.article})`}
                initialMessage={`Интересует информация о цене и сроках поставки следующего товара: "${this.props.item.name}" (${this.props.item.article}). Ответ прошу отправить мне на почту или позвоните мне на выше указанный номер.`}
                isModalShown={this.state.isMessageModalShown}
                onModalHide={this.handleMessageModalHide}
                emailRequired
              />
            )}

            {!this.props.quantity && (
              <Modal
                show={this.state.isQuantityModalShown}
                title={`Добавление "${this.props.item.name}" в корзину`}
                actions={[
                  <AddToCartButton
                    item={this.props.item}
                    quantity={this.state.quantity}
                    onSuccess={this.handleSuccess}
                  />,
                  <Button neutral inline onClick={this.handleQuantityModalHide}>
                    Отмена
                  </Button>,
                ]}
                onHide={this.handleQuantityModalHide}
              >
                <p>Пожалуйста, укажите количество:</p>
                <StyledQuantityInput
                  value={this.state.quantity}
                  onChange={this.handleQuantityChange}
                />
              </Modal>
            )}

            <Modal
              show={this.state.isSuccessModalShown}
              title={`Товар "${this.props.item.name}" добавлен в корзину`}
              actions={[
                <Button primary inline onClick={this.handleSuccessModalHide}>
                  Продолжить покупки
                </Button>,
                <ButtonLink neutral inline to="/cart">
                  Перейти к корзине
                </ButtonLink>,
              ]}
              onHide={this.handleSuccessModalHide}
            />
          </>
        )}

        <UpdateCartMutation
          variables={{ itemId: this.props.item.id, quantity: +this.props.quantity, action: 'ADD' }}
          onError={this.handleError}
          onCompleted={this.props.onSuccess || this.handleSuccess}
        >
          {(runMutation, { loading }) => (
            <Button
              inline
              primary={this.props.item.residue > 0}
              accent2={this.props.item.residue === 0}
              onClick={() => this.handleClick(runMutation)}
              className={this.props.className}
              disabled={loading}
            >
              {loading ? (
                'Загрузка...'
              ) : this.props.item.residue > 0 ? (
                <>
                  <StyledCartIcon /> Купить
                </>
              ) : (
                'Заказать'
              )}
            </Button>
          )}
        </UpdateCartMutation>
      </>
    );
  }
}

export default AddToCartButton;
