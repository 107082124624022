import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import constants from 'constants/constants';
import Link from 'components/atoms/Link';
import Label from 'components/atoms/Label';
import AddToBookmarksButton from 'components/molecules/AddToBookmarksButton';
import AddToCartButton from 'components/molecules/AddToCartButton';

const PUBLIC_BUCKET_URL = constants.PUBLIC_BUCKET_URL;

const Wrapper = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
`;

const Img = styled(({ src, showImage, ...rest }) => <div {...rest} />)`
  padding-bottom: 66%;
  margin: -0.125em -0.125em 0;
  border-radius: 0.25em 0.25em 0 0;
  cursor: pointer;
  ${({ src, showImage }) =>
    showImage &&
    src &&
    `
      background: url("${PUBLIC_BUCKET_URL + src.value.thumb}") center center no-repeat;
  `};
  background-color: ${({ theme }) => theme.colors.neutral5};
  background-size: contain;
  position: relative;
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const ImageWrapper = ({ innerRef, ...rest }) => <StyledImageWrapper ref={innerRef} {...rest} />;

const StyledLabel = styled(Label)`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
`;

const NoImageMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 0.5em;
  right: 0.5em;
  transform: translateY(-50%);
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.neutral4};
`;

const ContentWrapper = styled.div`
  margin: 0.5em;
`;

const Title = styled(Link)`
  overflow: hidden;
  font-size: 1.2em;
  line-height: 1.2em;
  height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0.5em 0;
  text-decoration: none;
`;

const PriceRow = styled.div`
  margin: 0.5rem -0.5rem;
  display: flex;
  align-items: flex-end;
`;

const Price = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 0 1 auto;
  margin: 0 0.5rem;
`;

const OldPrice = styled.div`
  font-size: 1.1em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral3};
  text-decoration: line-through;
  flex: none;
  margin: 0 0.5rem;
`;

const Description = styled.p`
  margin: 0.5em 0;
  line-height: 1.2em;
  height: 3.6em;
  color: ${({ theme }) => theme.colors.neutral3};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ActionsWrapper = styled.div`
  margin: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAddToBookmarksButton = styled(AddToBookmarksButton)`
  margin-left: -0.5em;
`;

@withRouter
export default class Card extends Component {
  state = {
    showImage: false,
  };

  showImage = () => {
    this.setState({
      showImage: true,
    });
  };

  render() {
    const {
      className,
      history,
      hideDescription,
      item: {
        id,
        shortDescription,
        name,
        price,
        images,
        category,
        isBookmarked,
        label,
        activeSaleItem,
      },
    } = this.props;

    const link = `/catalog/${category.translit}/${id}`;

    const image = images[0];

    return (
      <Wrapper className={className}>
        <Waypoint onEnter={this.showImage}>
          <ImageWrapper>
            <Img
              src={image}
              showImage={this.state.showImage}
              onClick={() => {
                history.push(link);
              }}
            >
              {!image && <NoImageMessage>Нет изображения</NoImageMessage>}
            </Img>
            {activeSaleItem && <StyledLabel color="danger">Акция!</StyledLabel>}
            {!activeSaleItem && label && (
              <StyledLabel color={label.color}>{label.text}</StyledLabel>
            )}
          </ImageWrapper>
        </Waypoint>
        <ContentWrapper>
          <Title to={link}>{name}</Title>
          <PriceRow>
            <Price>{price ? `${price.toFixed(2)} ₽` : 'Цена не указана'}</Price>
            {activeSaleItem && activeSaleItem.oldPrice && (
              <OldPrice>{`${activeSaleItem.oldPrice.toFixed(2)} ₽`}</OldPrice>
            )}
          </PriceRow>
          {!hideDescription && (
            <Description
              dangerouslySetInnerHTML={{
                __html: shortDescription ? shortDescription.replace(/(<([^>]+)>)/gi, ' ') : '',
              }}
            />
          )}
        </ContentWrapper>
        <ActionsWrapper>
          <StyledAddToBookmarksButton isBookmarked={isBookmarked} itemId={id} />
          <AddToCartButton item={this.props.item} />
        </ActionsWrapper>
      </Wrapper>
    );
  }
}
