import styled from 'styled-components';
import List from './List';

const Wrapper = styled.div``;

const Items = ({ className, ...rest }) => (
  <Wrapper className={className}>
    <List {...rest} />
  </Wrapper>
);

export default Items;
