import styled from 'styled-components';
import Card from './Card';

const Wrapper = styled.div`
  margin: -0.5em;
  display: flex;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  margin: 0.5em;
  width: calc(100% - 1em);

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}em) {
    width: ${({ cols }) => `calc((100% - ${cols[0]}em) / ${cols[0]})`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: ${({ cols }) => `calc((100% - ${cols[1]}em) / ${cols[1]})`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}em) {
    width: ${({ cols }) => `calc((100% - ${cols[2]}em) / ${cols[2]})`};
  }
`;

const List = ({ items, cols, hideDescription }) => (
  <Wrapper>
    {items.map((item) => (
      <StyledCard cols={cols} hideDescription={hideDescription} key={item.id} item={item} />
    ))}
  </Wrapper>
);

List.defaultProps = {
  cols: [2, 2, 3],
};

export default List;
